// Generated by Framer (3def70c)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as localizedValues from "./sVy5px2UI-0.js";

const cycleOrder = ["OPGkCOlRk", "QCNpATOep", "bU24ivC8K", "t1E7AVqIT", "CPkMMYGpg", "cxcHaep_X"];

const serializationHash = "framer-5SUvE"

const variantClassNames = {bU24ivC8K: "framer-v-w4vh03", CPkMMYGpg: "framer-v-qmjykw", cxcHaep_X: "framer-v-m4pzw5", OPGkCOlRk: "framer-v-1kzutny", QCNpATOep: "framer-v-2n9pmy", t1E7AVqIT: "framer-v-107jrdp"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const valuesByLocaleId = {ZULQJWnvF: localizedValues}

const getLocalizedValue = (key, locale) => {
while (locale) {
const values = valuesByLocaleId[locale.id]
if (values) {
const value = values[key]
if (value) {
return value
}
}
locale = locale.fallback
}
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {L: "cxcHaep_X", Large: "bU24ivC8K", M: "CPkMMYGpg", Mid: "QCNpATOep", S: "t1E7AVqIT", Small: "OPGkCOlRk"}

const getProps = ({height, id, image, mouseEnter, width, ...props}) => { return {...props, aDKSxZlzM: image ?? props.aDKSxZlzM ?? {src: "https://framerusercontent.com/images/jvYiXc9KpfFDBRBxgcvHFNvvjY.png"}, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "OPGkCOlRk", woeiENPnd: mouseEnter ?? props.woeiENPnd} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;image?: {src: string; srcSet?: string};mouseEnter?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, aDKSxZlzM, woeiENPnd, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureHandlers, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "OPGkCOlRk", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onMouseEnter160spbc = activeVariantCallback(async (...args) => {
setGestureState({isHovered: true})
if (woeiENPnd) {const res = await woeiENPnd(...args);
if (res === false) return false;}
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1kzutny", className, classNames)} data-framer-name={"Small"} data-highlight layoutDependency={layoutDependency} layoutId={"OPGkCOlRk"} onMouseEnter={onMouseEnter160spbc} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({bU24ivC8K: {"data-framer-name": "Large"}, CPkMMYGpg: {"data-framer-name": "M"}, cxcHaep_X: {"data-framer-name": "L"}, QCNpATOep: {"data-framer-name": "Mid"}, t1E7AVqIT: {"data-framer-name": "S"}}, baseVariant, gestureVariant)}><Image background={{alt: "", fit: "fill", intrinsicHeight: 128, intrinsicWidth: 128, pixelHeight: 256, pixelWidth: 256, sizes: "70px", ...toResponsiveImage(aDKSxZlzM)}} className={"framer-28mj82"} data-framer-name={"iMail"} layoutDependency={layoutDependency} layoutId={"uZr1vtYNg"} {...addPropertyOverrides({bU24ivC8K: {background: {alt: "", fit: "fill", intrinsicHeight: 128, intrinsicWidth: 128, pixelHeight: 256, pixelWidth: 256, sizes: "110px", ...toResponsiveImage(aDKSxZlzM)}}, CPkMMYGpg: {background: {alt: "", fit: "fill", intrinsicHeight: 128, intrinsicWidth: 128, pixelHeight: 256, pixelWidth: 256, sizes: "54px", ...toResponsiveImage(aDKSxZlzM)}}, cxcHaep_X: {background: {alt: "", fit: "fill", intrinsicHeight: 128, intrinsicWidth: 128, pixelHeight: 256, pixelWidth: 256, sizes: "66px", ...toResponsiveImage(aDKSxZlzM)}}, QCNpATOep: {background: {alt: "", fit: "fill", intrinsicHeight: 128, intrinsicWidth: 128, pixelHeight: 256, pixelWidth: 256, sizes: "90px", ...toResponsiveImage(aDKSxZlzM)}}, t1E7AVqIT: {background: {alt: "", fit: "fill", intrinsicHeight: 128, intrinsicWidth: 128, pixelHeight: 256, pixelWidth: 256, sizes: "42px", ...toResponsiveImage(aDKSxZlzM)}}}, baseVariant, gestureVariant)}/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-5SUvE.framer-da8yaa, .framer-5SUvE .framer-da8yaa { display: block; }", ".framer-5SUvE.framer-1kzutny { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: hidden; padding: 0px; position: relative; width: min-content; }", ".framer-5SUvE .framer-28mj82 { aspect-ratio: 1 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 70px); overflow: visible; position: relative; width: 70px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-5SUvE.framer-1kzutny { gap: 0px; } .framer-5SUvE.framer-1kzutny > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-5SUvE.framer-1kzutny > :first-child { margin-left: 0px; } .framer-5SUvE.framer-1kzutny > :last-child { margin-right: 0px; } }", ".framer-5SUvE.framer-v-2n9pmy .framer-28mj82 { height: var(--framer-aspect-ratio-supported, 90px); width: 90px; }", ".framer-5SUvE.framer-v-w4vh03 .framer-28mj82 { height: var(--framer-aspect-ratio-supported, 110px); width: 110px; }", ".framer-5SUvE.framer-v-107jrdp .framer-28mj82 { height: var(--framer-aspect-ratio-supported, 42px); width: 42px; }", ".framer-5SUvE.framer-v-qmjykw .framer-28mj82 { height: var(--framer-aspect-ratio-supported, 54px); width: 54px; }", ".framer-5SUvE.framer-v-m4pzw5 .framer-28mj82 { height: var(--framer-aspect-ratio-supported, 66px); width: 66px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 70
 * @framerIntrinsicWidth 70
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"QCNpATOep":{"layout":["auto","auto"]},"bU24ivC8K":{"layout":["auto","auto"]},"t1E7AVqIT":{"layout":["auto","auto"]},"CPkMMYGpg":{"layout":["auto","auto"]},"cxcHaep_X":{"layout":["auto","auto"]}}}
 * @framerVariables {"aDKSxZlzM":"image","woeiENPnd":"mouseEnter"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramersVy5px2UI: React.ComponentType<Props> = withCSS(Component, css, "framer-5SUvE") as typeof Component;
export default FramersVy5px2UI;

FramersVy5px2UI.displayName = "iMail";

FramersVy5px2UI.defaultProps = {height: 70, width: 70};

addPropertyControls(FramersVy5px2UI, {variant: {options: ["OPGkCOlRk", "QCNpATOep", "bU24ivC8K", "t1E7AVqIT", "CPkMMYGpg", "cxcHaep_X"], optionTitles: ["Small", "Mid", "Large", "S", "M", "L"], title: "Variant", type: ControlType.Enum}, aDKSxZlzM: {__defaultAssetReference: "data:framer/asset-reference,jvYiXc9KpfFDBRBxgcvHFNvvjY.png?originalFilename=image.png&preferredSize=auto", title: "Image", type: ControlType.ResponsiveImage}, woeiENPnd: {title: "Mouse Enter", type: ControlType.EventHandler}} as any)

addFonts(FramersVy5px2UI, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})